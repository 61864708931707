// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "channels"
import '../js/bootstrap_js_files.js'

import $ from 'jquery';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

var $grid = document.getElementById('masonry-grid');
imagesLoaded($grid, function() {
  var msnry = new Masonry($grid, {
    itemSelector: '.col',
  });
});

$(document).ready(function() {
});

Rails.start()